<template>
  <v-list
    class="user-menu py-0 font-weight-bold"
    min-width="248"
    style="border: 3px solid var(--v-grey-base);"
  >
    <v-list-item :to="{ name: 'ProfileWall' }">
      <v-list-item-title>{{ $t("profile.menu.wall") }}</v-list-item-title>
    </v-list-item>
    <v-divider />
    <v-list-item :to="{ name: 'ProfileUpdate' }">
      <v-list-item-title>{{ $t("profile.menu.profile") }}</v-list-item-title>
    </v-list-item>

    <template v-if="user.userType.userTypeId == 3">
      <v-divider />
      <v-list-item :to="{ name: 'Rider' }">
        <v-list-item-title>{{ $t("profile.menu.rider") }}</v-list-item-title>
      </v-list-item>
    </template>

    <!--

      <v-divider />
      <v-list-item :to="{ name: 'NotificationsHistory' }">
        <v-list-item-title>{{
          $t("profile.menu.notificationHistory")
        }}</v-list-item-title>
      </v-list-item>
      
    -->
    <v-divider />
    <v-list-item :to="{ name: 'PaymentDueDate' }">
      <v-list-item-title>{{
        $t("profile.menu.paymentDueDate")
      }}</v-list-item-title>
    </v-list-item>

    <v-divider />
    <v-list-item :to="{ name: 'Orders' }">
      <v-list-item-title>{{ $t("profile.menu.orders") }}</v-list-item-title>
    </v-list-item>
    <v-divider />
    <v-list-item :to="{ name: 'Lists' }">
      <v-list-item-title>{{ $t("profile.menu.lists") }}</v-list-item-title>
    </v-list-item>
    <v-divider />
    <v-list-item :to="{ name: 'Addresses' }">
      <v-list-item-title>{{ $t("profile.menu.addresses") }}</v-list-item-title>
    </v-list-item>
    <!-- <v-divider />
    <v-list-item :to="{ name: 'MyPaymentMethods' }">
      <v-list-item-title>{{
        $t("profile.menu.paymentMethods")
      }}</v-list-item-title>
    </v-list-item> -->
    <v-divider />
    <v-list-item :to="{ name: 'ProfileGift' }">
      <v-list-item-title>{{ $t("profile.menu.coupon") }}</v-list-item-title>
    </v-list-item>
    <v-divider />
    <v-list-item :to="{ name: 'Invite' }">
      <v-list-item-title>{{
        $t("profile.menu.inviteFriend")
      }}</v-list-item-title>
    </v-list-item>
    <!-- <v-divider />
    <v-list-item :to="{ name: 'ProfilePoints' }">
      <v-list-item-title>{{ $t("profile.menu.wallet") }}</v-list-item-title>
    </v-list-item> -->
    <!-- <v-divider />
    <v-list-item :to="{ name: 'BookerAwards' }" v-if="fidelityCard">
      <v-list-item-title>{{ $t("profile.menu.awards") }}</v-list-item-title>
    </v-list-item> -->
    <v-divider />
    <v-list-item :to="{ name: 'Page', params: { postName: 'Eugenio' } }">
      <v-list-item-title>{{ $t("profile.menu.euGenio") }}</v-list-item-title>
    </v-list-item>
    <v-divider />
    <v-list-item to="/profile/news-list">
      <v-list-item-title>{{ $t("profile.menu.messages") }}</v-list-item-title>
    </v-list-item>
    <v-list-item @click.stop="onLogoutClick" class="my-1">
      <v-btn block outlined color="primary">{{
        $t("profile.menu.logout")
      }}</v-btn>
    </v-list-item>
  </v-list>
</template>
<style lang="scss">
.user-menu {
  @media #{map-get($display-breakpoints, 'xs-only')} {
    .v-list-item {
      min-height: 40px;
    }
  }
}
</style>
<script>
import { mapActions, mapGetters } from "vuex";
import get from "lodash/get";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      user: "cart/getUser"
    }),
    fidelityCard() {
      return get(this.user, "fidelityCard");
    }
  },
  methods: {
    ...mapActions({
      logout: "cart/doLogout"
    }),
    async doLogout() {
      let res = await this.logout();
      if (res) {
        setTimeout(() => {
          this.$router.push({
            name: "HomeSite",
            path: "/"
          });
        }, 200);
      }
    },
    async onLogoutClick() {
      let isLoggedOut = await this.doLogout();
      if (isLoggedOut) {
        if (window.fcPreChatform) {
          window.document.getElementById("chat-fc-nome").value = "";
          window.document.getElementById("chat-fc-email").value = "";
        }
        if (window.fcWidget) {
          let loggedOut = await window.fcWidget.user.clear();
          if (loggedOut) window.fcWidget.close();
        }
        if (this.$route.name !== "HomeSite") {
          this.$router.push({ name: "HomeSite" });
        }
      }
    }
  }
};
</script>
